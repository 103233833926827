@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
}

html, body{
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.ps-menu-root{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 999;
}

::-webkit-scrollbar {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}