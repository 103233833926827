.marked-date {
    background-color: #C56DC5; /* Cambia el color de fondo de las fechas marcadas */
    color: white; /* Cambia el color del texto en las fechas marcadas */
}

.react-calendar {
    /* Estilo para el calendario en general */
    background: transparent;
    border: 2px solid #292929;
    width: 100%;
    color: #C9C9C9;
  }
  
  .react-calendar__navigation {
    /* Estilo para la navegación del calendario */
  }
  
  .react-calendar__navigation__label {
    /* Estilo para la etiqueta de mes y año */
    color: #FDFDFD;
    font-size: 1.1rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .react-calendar__navigation button:enabled:hover {
    /* Estilo para la etiqueta de mes y año */
    background-color: #C56DC5;
    color: #FDFDFD;
    font-size: 1.1rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .react-calendar__navigation__arrow {
    /* Estilo para los botones de navegación */
    color: #FDFDFD;
  }

  .react-calendar__navigation__arrow:hover {
    background-color: yellow;
  }
  
  .react-calendar__month-view {
    /* Estilo para la vista de mes en el calendario */
  }
  
  .react-calendar__month-view__weekdays {
    /* Estilo para el encabezado de los días de la semana */
  }
  
  .react-calendar__month-view__weekdays__weekday {
    /* Estilo para los días de la semana en el encabezado */
    font-size: 1.2em;
    text-decoration: none;
  }

  .react-calendar__month-view__weekdays__weekday abbr{
    /* Estilo para los días de la semana en el encabezado */
    text-decoration: none;
  }
  
  .react-calendar__month-view__days {
    /* Estilo para el contenedor de los días del mes */
  }
  
  .react-calendar__tile {
    /* Estilo para cada elemento de día del calendario */
    color: #C9C9C9;
    height: 4rem;
    font-size: 1.1rem;
  }

  .react-calendar__tile:enabled:hover {
    /* Estilo para cada elemento de día del calendario */
    background: #C56DC5;
    color: black;
    height: 4rem;
    font-weight: bold;
  }
  
  .react-calendar__tile--active {
    /* Estilo para el día seleccionado */
  }
  
  .react-calendar__tile--hasActive {
    /* Estilo para los días con eventos */
  }
  
  .react-calendar__tile--now {
    /* Estilo para el día actual */
  }
  
  .react-calendar__tile--rangeStart {
    /* Estilo para el primer día de un rango de selección */
    border: 1px solid;
    border-color: #C56DC5;
    color: white;
  }
  
  .react-calendar__tile--rangeEnd {
    /* Estilo para el último día de un rango de selección */
  }
  
  .react-calendar__tile--range {
    /* Estilo para los días en un rango de selección */
  }
  
  .react-calendar__tile--hover {
    /* Estilo para el día cuando se pasa el mouse sobre él */
    background-color: #BE5BBE; /* Cambia el color de fondo de las fechas marcadas */
    color: white;
  }
  